import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { resetButton } from 'Components/Styles'
import { IconButton } from 'Components/UI'

export const RelativeContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
`

export const NotificationCounter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -2px;
  right: -4px;
  border-radius: 100px;
  background-color: ${themeGet('colors.primaryCardinal')};
  color: ${themeGet('colors.white')};
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  padding: 2px 6px;
  letter-spacing: 0.21px;
`

export const IconButtonStyled = styled(IconButton)`
  background-color: ${themeGet('colors.white')};
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.02),
    0px 0px 13px -15px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
`

const reset = `
  margin: 0;
  padding: 0;
`

export const NotiHdg1 = styled.h3`
  ${reset}
  font-size: 10px;
  text-transform: uppercase;
  color: #757575;
  background-color: #f0f2f3;
  padding: 4px 12px;
  position: sticky;
  top: 0;
  z-index: 1;
`

export const NotiHdg2 = styled.h4`
  ${reset}
  font-weight: 600;
  font-size: 14px;
  color: #2d2d2d;
  line-height: 16px;
`

export const NotiList = styled.ul`
  ${reset}
  list-style: none;
  min-width: 312px;
`

export const NotiListWrapper = styled.div`
  max-height: 420px;
  overflow-y: auto;
  scroll-behavior: smooth;
`

export const NotiListItem = styled.li<{ read: boolean }>`
  ${reset}
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: ${({ read }) => (read ? 'transparent' : '2px solid #cd1c45')};

  + li {
    border-top: 1px solid #dde1e4;
  }
`
export const NotiContent = styled.p`
  ${reset}
  font-size: 12px;
  color: #757575;
  line-height: 16px;
`
export const NotiDetails = styled.button<{ read: boolean }>`
  ${resetButton}

  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  cursor: pointer;
  text-decoration: none;
  color: ${({ read }) => (read ? '#757575' : '#cd1c45')};

  &:hover,
  &:active {
    text-decoration: underline;
  }
`
export const NotiHdgCount = styled.span`
  ${reset}
  background-color: #e0e2e3;

  border-radius: 100px;
  margin-left: 4px;
  padding: 2px 4px;
  min-width: 16px;
  display: inline-flex;
  line-height: 1;
  justify-content: center;
`

export const ProgressWrapper = styled.div`
  position: absolute;
  top: -4px;
  left: -4px;
  z-index: -1;

  > svg {
    width: 46px;
    height: 46px;
    opacity: 0.5;
  }
`

export const ProgressCircle = styled.svg`
  width: 22px;
  height: 22px;
  animation: rotate 2s linear infinite;

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`

export const Circle = styled.circle`
  fill: none;
  stroke: ${themeGet('colors.primaryCardinal')};
  stroke-width: 5;
  stroke-linecap: round;
  stroke-dasharray: 1, 150;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite;

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`
