import { TAG_COLOR_KIND } from 'Constants/tags'

import shadows from './shadows'

import palette from '../palette'

const colors = {
  main: palette.primary80,
  hover: palette.primary90,
  focus: palette.primary100,
  disabled: palette.primary30,
  success: palette.success40,
  info: palette.info40,
  warning: palette.warning40,
  error: palette.error30,

  bg: {
    primary: palette.primaryNeutral0,
    secondary: palette.secondaryNeutral100,
    tertiary: palette.primaryNeutral10,
    quaternary: palette.secondaryNeutral30,

    body: palette.primaryNeutral0,
    disabled: palette.secondaryNeutral10,
    error: palette.error10,
    hover: palette.primary10,
    info: palette.info10,
    overlay: palette.primaryNeutral10,
    overlayPopUp: palette.secondaryNeutral0,
    success: palette.success10,
    tooltip: palette.primaryNeutral90,
    warning: palette.warning10,

    winterLakes: palette.winterLakes10,
    skylar: palette.skylar10,
    hokkaidoLavender: palette.hokkaidoLavender10,
    pasadenaRose: palette.pasadenaRose10,
    goldenSpice: palette.goldenSpice10,
    shutterCopper: palette.shutterCopper10,
    clematisGreen: palette.clematisGreen10,
  },

  divider: {
    default: palette.primaryNeutral20,
    hover: palette.primaryNeutral50,
    disabled: palette.secondaryNeutral20,
  },

  text: {
    body: palette.primaryNeutral70,
    contrast: palette.primaryNeutral0,
    disabled: palette.secondaryNeutral40,
    error: palette.error30,
    header: palette.primaryNeutral100,
    info: palette.info40,
    main: palette.primary80,
    placeholder: '#757575',
    success: palette.success40,
    warning: palette.warning40,
  },

  button: {
    basic: {
      primary: {
        bg: palette.primary80,
        border: palette.primary80,
        color: palette.primaryNeutral0,

        hover: {
          bg: palette.primary90,
          border: palette.primary90,
        },

        focus: {
          bg: palette.primary100,
          border: palette.primary100,
          shadow: shadows.primaryFocus,
        },

        disabled: {
          bg: '#F0F0F0',
          border: '#F0F0F0',
          color: '#757575',
        },
      },

      secondary: {
        bg: 'transparent',
        border: palette.primaryNeutral20,
        color: palette.primaryNeutral100,

        hover: {
          bg: palette.primaryNeutral10,
          border: palette.primaryNeutral20,
        },

        focus: {
          bg: palette.primaryNeutral10,
          border: palette.primaryNeutral20,
          shadow: shadows.secondaryFocus,
        },

        disabled: {
          bg: 'transparent',
          border: palette.primaryNeutral20,
          color: palette.primaryNeutral40,
        },
      },

      danger: {
        bg: palette.error30,
        border: palette.error30,
        color: palette.primaryNeutral0,

        hover: {
          bg: palette.error40,
          border: palette.error40,
        },

        focus: {
          bg: palette.error50,
          border: palette.error50,
          shadow: shadows.dangerFocus,
        },

        disabled: {
          bg: palette.error20,
          border: palette.error20,
        },
      },
    },

    text: {
      primary: {
        bg: 'transparent',
        border: 'transparent',
        color: palette.primary80,

        hover: {
          bg: palette.primary5,
          border: palette.primary5,
        },

        focus: {
          bg: palette.primary5,
          border: palette.primary5,
          shadow: shadows.primaryFocus,
        },

        disabled: {
          bg: 'transparent',
          border: 'transparent',
          color: palette.primary30,
        },
      },

      secondary: {
        bg: 'transparent',
        border: 'transparent',
        color: palette.primaryNeutral100,

        hover: {
          bg: palette.primaryNeutral10,
          border: palette.primaryNeutral10,
        },

        focus: {
          bg: palette.primaryNeutral10,
          border: palette.primaryNeutral10,
          shadow: shadows.secondaryFocus,
        },

        disabled: {
          bg: 'transparent',
          border: 'transparent',
          color: palette.primaryNeutral40,
        },
      },

      danger: {
        bg: 'transparent',
        border: 'transparent',
        color: palette.error30,

        hover: {
          bg: palette.error10,
          border: palette.error10,
        },

        focus: {
          bg: palette.error10,
          border: palette.error10,
          shadow: shadows.dangerFocus,
        },

        disabled: {
          bg: 'transparent',
          border: 'transparent',
          color: palette.primaryNeutral40,
        },
      },
    },

    icon: {
      primary: {
        bg: palette.primary80,
        border: palette.primary80,
        color: palette.primaryNeutral0,

        hover: {
          bg: palette.primary90,
          border: palette.primary90,
        },

        focus: {
          bg: palette.primary100,
          border: palette.primary100,
          shadow: shadows.primaryFocus,
        },

        disabled: {
          bg: palette.primary30,
          border: palette.primary30,
        },
      },

      outline: {
        bg: 'transparent',
        border: palette.primaryNeutral20,
        color: palette.primaryNeutral100,

        hover: {
          bg: palette.primaryNeutral10,
          border: palette.primaryNeutral20,
        },

        focus: {
          bg: palette.primaryNeutral10,
          border: palette.primaryNeutral20,
          shadow: shadows.secondaryFocus,
        },

        disabled: {
          bg: 'transparent',
          border: palette.primaryNeutral20,
          color: palette.primaryNeutral40,
        },
      },

      secondary: {
        bg: 'transparent',
        border: 'transparent',
        color: palette.primaryNeutral100,

        hover: {
          bg: palette.primaryNeutral10,
          border: palette.primaryNeutral10,
        },

        focus: {
          bg: palette.primaryNeutral10,
          border: palette.primaryNeutral10,
          shadow: shadows.secondaryFocus,
        },

        disabled: {
          bg: 'transparent',
          border: 'transparent',
          color: palette.primaryNeutral40,
        },
      },

      tertiary: {
        bg: palette.primaryNeutral0,
        border: palette.primaryNeutral20,
        color: palette.primaryNeutral100,

        hover: {
          bg: palette.primaryNeutral10,
          border: palette.primaryNeutral20,
        },

        focus: {
          bg: palette.primaryNeutral10,
          border: palette.primaryNeutral20,
          shadow: shadows.secondaryFocus,
        },

        disabled: {
          bg: palette.primaryNeutral10,
          border: palette.primaryNeutral20,
          color: palette.primaryNeutral40,
        },
      },

      danger: {
        bg: 'transparent',
        border: 'transparent',
        color: palette.error30,

        hover: {
          bg: palette.error10,
          border: palette.error10,
        },

        focus: {
          bg: palette.error10,
          border: palette.error10,
          shadow: shadows.dangerFocus,
        },

        disabled: {
          bg: 'transparent',
          border: 'transparent',
          color: palette.primaryNeutral40,
        },
      },
    },
  },

  link: {
    colorDisabled: palette.secondaryNeutral40,

    primary: {
      color: palette.primary80,
      colorHover: palette.primary90,
      colorFocus: palette.primary100,
    },

    secondary: {
      color: palette.primaryNeutral100,
      colorHover: palette.primary90,
      colorFocus: palette.primary100,
    },

    success: {
      color: palette.success40,
      colorHover: palette.success50,
      colorFocus: palette.success60,
    },

    danger: {
      color: palette.error30,
      colorHover: palette.error50,
      colorFocus: palette.error60,
    },

    info: {
      color: palette.info40,
      colorHover: palette.info50,
      colorFocus: palette.info60,
    },
  },

  checkbox: {
    bg: palette.primaryNeutral0,
    border: palette.primaryNeutral20,
    hover: {
      border: palette.primary90,
    },
    focus: {
      border: palette.primary100,
      shadow: shadows.primaryFocus,
    },
    disabled: {
      bg: palette.primaryNeutral10,
      border: palette.primaryNeutral20,
    },

    selected: {
      color: palette.primaryNeutral0,
      bg: palette.primary80,
      border: palette.primary80,
      hover: {
        bg: palette.primary90,
        border: palette.primary90,
      },
      focus: {
        bg: palette.primary100,
        border: palette.primary100,
        shadow: shadows.primaryFocus,
      },
      disabled: {
        bg: palette.primary30,
        border: palette.primary30,
      },
    },
  },

  radioButton: {
    bg: palette.primaryNeutral0,
    border: palette.primaryNeutral20,

    hover: {
      border: palette.primary90,
    },

    focus: {
      border: palette.primary100,
      shadow: shadows.primaryFocus,
    },

    disabled: {
      bg: palette.secondaryNeutral10,
      border: palette.secondaryNeutral20,
    },

    selected: {
      border: palette.primary80,

      disabled: {
        bg: palette.primaryNeutral0,
        border: palette.secondaryNeutral20,
      },
    },
  },

  switch: {
    color: palette.primaryNeutral0,
    bg: palette.primaryNeutral20,
    hover: {
      bg: palette.primaryNeutral50,
    },
    focus: {
      bg: palette.primaryNeutral20,
      shadow: shadows.secondaryFocus,
    },
    disabled: {
      bg: palette.secondaryNeutral20,
    },

    on: {
      color: palette.primaryNeutral0,
      bg: palette.primary80,
      hover: {
        bg: palette.primary90,
      },
      focus: {
        bg: palette.primary100,
        shadow: shadows.primaryFocus,
      },
      disabled: {
        bg: palette.primary30,
      },
    },
  },

  avatar: {
    bg: palette.primaryNeutral20,
    border: palette.primaryNeutral20,
    color: palette.primaryNeutral0,
    textBg: palette.primary80,
  },

  toast: {
    bg: palette.primaryNeutral0,
    shadow: shadows.box,
  },

  menuItem: {
    bg: 'transparent',
    color: palette.primaryNeutral70,

    hover: {
      bg: palette.primaryNeutral10,
      color: palette.primaryNeutral70,
    },

    active: {
      bg: palette.primaryNeutral10,
      color: palette.primaryNeutral100,
    },
  },

  tab: {
    color: palette.primaryNeutral70,
    borderColor: 'transparent',

    hover: {
      color: palette.primary80,
      borderColor: 'transparent',
    },

    active: {
      color: palette.primary80,
      borderColor: palette.primary80,
    },
  },

  badge: {
    bg: palette.primary80,
  },

  tag: {
    [TAG_COLOR_KIND.USER]: {
      bg: '#FBEDF0',
      border: '#FBEDF0',
      color: '#991533',
      graph: '#EBA4B4',
    },
    [TAG_COLOR_KIND.COMPANY]: {
      bg: palette.winterLakes10,
      border: palette.winterLakes10,
      color: palette.winterLakes50,
    },
    [TAG_COLOR_KIND.CONNECT]: {
      bg: palette.skylar10,
      border: palette.skylar10,
      color: palette.skylar50,
    },
    [TAG_COLOR_KIND.SKILL]: {
      bg: '#FDEDE0',
      border: '#FDEDE0',
      color: '#A64B00',
      graph: '#F19C55',
    },
    [TAG_COLOR_KIND.COMMUNITY]: {
      bg: '#F8E8EC',
      border: '#F8E8EC',
      color: '#BD324A',
      graph: '#F8E8EC',
    },
    [TAG_COLOR_KIND.NOTE]: {
      bg: palette.hokkaidoLavender10,
      border: palette.hokkaidoLavender10,
      color: palette.hokkaidoLavender50,
    },
    [TAG_COLOR_KIND.EVENT]: {
      bg: '#EFE7FD',
      border: '#EFE7FD',
      color: '#5427A1',
      graph: '#A97FD5',
    },
    [TAG_COLOR_KIND.MESSAGE]: {
      bg: palette.goldenSpice10,
      border: palette.goldenSpice10,
      color: palette.goldenSpice50,
    },
    [TAG_COLOR_KIND.INDUSTRY]: {
      bg: palette.goldenSpice10,
      border: palette.goldenSpice10,
      color: palette.goldenSpice50,
    },
    [TAG_COLOR_KIND.NEED_INDUSTRIES]: {
      bg: palette.goldenSpice10,
      border: palette.goldenSpice10,
      color: palette.goldenSpice50,
    },
    [TAG_COLOR_KIND.PROJECT]: {
      bg: '#E4F7ED',
      border: '#E4F7ED',
      color: '#116639',
      graph: '#2BCBBA',
    },
    [TAG_COLOR_KIND.ORGANIZATION]: {
      bg: '#E7EDFA',
      border: '#E7EDFA',
      color: '#234187',
      graph: '#50A9EE',
    },
    [TAG_COLOR_KIND.GROUP]: {
      bg: '#E7F9F8',
      border: '#E7F9F8',
      color: '#185E58',
      graph: '#4ACD88',
    },
    [TAG_COLOR_KIND.ROLE]: {
      bg: '#E4F2FE',
      border: '#E4F2FE',
      color: '#145D96',
      graph: '#E2645D',
    },
    [TAG_COLOR_KIND.CUSTOM]: {
      bg: '#FBE5EF',
      border: '#FBE5EF',
      color: '#941A50',
      graph: '#E25393',
    },
    [TAG_COLOR_KIND.SEARCH_KEYWORD]: {
      bg: '#DDE1E461',
      border: '#DDE1E461',
      color: '#2D2D2D',
    },
    [TAG_COLOR_KIND.DEFAULT]: {
      bg: '#FFFFFF',
      border: '#DDE1E4',
      color: '#2D2D2D',
    },
  },

  pagination: {
    bg: palette.primaryNeutral0,
    color: palette.primaryNeutral100,

    hover: {
      bg: palette.primaryNeutral10,
      color: palette.primaryNeutral100,
    },

    active: {
      color: palette.primaryNeutral0,
      bg: palette.primary80,
    },

    disabled: {
      color: palette.secondaryNeutral40,
    },
  },

  modal: {
    bg: palette.primaryNeutral0,
    overlay: `${palette.primaryNeutral100}cc`,
    divider: palette.primaryNeutral20,
    closeButton: palette.primaryNeutral100,
  },

  border: {
    primary: '#F0F0F0',
    secondary: palette.primaryNeutral20,
  },
}

export default colors
