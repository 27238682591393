import React, {
  forwardRef,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react'

import { IconBell } from '@tabler/icons-react'
import { format } from 'date-fns'
import {
  getTaskKindDisplayName,
  INotificationStatus,
  TaskKind,
} from 'Features/Notifications/getDisplayName'
import Notifications from 'Features/Notifications/Notifications'
import notificationsQuery from 'GraphQL/Queries/Community/notifications.graphql'

import { ContextMenu, Tooltip } from 'Components/UI'

import { NotificationKind } from 'Constants/mainGraphQL'

import { useCommunityContext } from 'Hooks'

import { useQuery, useSubscription } from 'Services/Apollo'
import _ from 'Services/I18n'

import notificationsSubscription from './Subscriptions/notificationsSubscription.graphql'
import {
  Circle,
  IconButtonStyled,
  NotiContent,
  NotiDetails,
  NotificationCounter,
  NotiHdg1,
  NotiHdg2,
  NotiHdgCount,
  NotiList,
  NotiListItem,
  NotiListWrapper,
  ProgressCircle,
  ProgressWrapper,
  RelativeContainer,
} from './styles'

export interface INotificationsButton {
  clear?: boolean
  onOpen?: MouseEventHandler<HTMLButtonElement>
  isOpen?: boolean
}

const NotificationsButton = forwardRef<HTMLButtonElement, INotificationsButton>(
  ({ clear = false, onOpen, isOpen }, ref) => {
    const { community } = useCommunityContext()
    const [viewingNotification, setViewingNotification] =
      useState<MainSchema.Notification | null>(null)
    const containerRef = useRef<HTMLDivElement>(null)

    const { data: taskNotificationsQuery, refetch } = useQuery<
      MainQueryData<'notifications'>,
      MainSchema.QueryNotificationsArgs
    >(notificationsQuery, {
      variables: {
        communityId: community?.id!,
        kind: NotificationKind.Task,
        limit: 1000,
      },
      skip: !community?.id,
    })

    useSubscription(notificationsSubscription, {
      shouldResubscribe: true,
      onData: async () => {
        await refetch()
      },
    })

    const taskNotifications = taskNotificationsQuery?.notifications.rows ?? []

    const notifications = [...taskNotifications].sort((a, b) => {
      const dateA = a.task?.startedAt
        ? new Date(a.task?.startedAt)
        : new Date(0)
      const dateB = b.task?.startedAt
        ? new Date(b.task?.startedAt)
        : new Date(0)
      return dateB.getTime() - dateA.getTime()
    })
    const notificationCount = notifications.filter(n => n.read === false).length
    const activeNotifications = notifications.filter(
      notification =>
        notification.task?.status === INotificationStatus.Pending ||
        notification.task?.status === INotificationStatus.Processing,
    )
    const completedNotifications = notifications.filter(
      notification =>
        notification.task?.status === INotificationStatus.Completed ||
        notification.task?.status === INotificationStatus.Cancelled ||
        notification.task?.status === INotificationStatus.Error,
    )

    const iconOptions = clear
      ? { large: true, secondary: true }
      : { outline: true }

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          containerRef.current &&
          !containerRef.current.contains(event.target as Node) &&
          isOpen
        ) {
          onOpen?.(event as unknown as React.MouseEvent<HTMLButtonElement>)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [isOpen, onOpen])

    const onClickNotification = (id: string | undefined) => {
      if (id) {
        const foundNotification = notifications.find(n => n.task?.id === id)
        setViewingNotification(foundNotification || null)
        onOpen?.(new MouseEvent('click') as any)
      }
    }

    return (
      <RelativeContainer ref={containerRef}>
        {viewingNotification && (
          <Notifications
            isActive={viewingNotification !== null}
            notification={viewingNotification}
            setViewingNotification={setViewingNotification}
          />
        )}
        <Tooltip content={_('tips.notifications')}>
          <IconButtonStyled ref={ref} onClick={onOpen} {...iconOptions}>
            {activeNotifications.length > 0 && (
              <ProgressWrapper>
                <ProgressCircle preserveAspectRatio="none" viewBox="0 0 50 50">
                  <Circle cx={25} cy={25} r={20} />
                </ProgressCircle>
              </ProgressWrapper>
            )}
            {notificationCount > 0 && (
              <NotificationCounter>{notificationCount}</NotificationCounter>
            )}
            <IconBell />
          </IconButtonStyled>
        </Tooltip>
        <ContextMenu isOpen={isOpen ?? false} left="auto" right={0} top={44}>
          <NotiListWrapper>
            {activeNotifications.length > 0 && (
              <>
                <NotiHdg1>
                  Active{' '}
                  <NotiHdgCount>{activeNotifications.length}</NotiHdgCount>
                </NotiHdg1>
                <NotiList>
                  {activeNotifications.map(notification => (
                    <NotiListItem
                      key={notification.task?.startedAt}
                      read={notification.read}
                    >
                      <div>
                        <NotiHdg2>
                          {getTaskKindDisplayName(
                            notification.task?.kind as TaskKind,
                          )}
                        </NotiHdg2>
                        <NotiContent>
                          Started:{' '}
                          {notification.task?.startedAt &&
                            format(
                              new Date(notification.task?.startedAt),
                              'PPp',
                            )}
                        </NotiContent>
                      </div>
                      <ProgressCircle
                        preserveAspectRatio="none"
                        viewBox="0 0 50 50"
                      >
                        <Circle cx={25} cy={25} r={20} />
                      </ProgressCircle>
                    </NotiListItem>
                  ))}
                </NotiList>
              </>
            )}
            {completedNotifications.length > 0 && (
              <>
                <NotiHdg1>
                  Completed{' '}
                  <NotiHdgCount>{completedNotifications.length}</NotiHdgCount>
                </NotiHdg1>
                <NotiList>
                  {completedNotifications.map(notification => (
                    <NotiListItem
                      key={notification.task?.startedAt}
                      read={notification.read}
                    >
                      <div>
                        <NotiHdg2>
                          {getTaskKindDisplayName(
                            notification.task?.kind as TaskKind,
                          )}
                        </NotiHdg2>
                        <NotiContent>
                          {notification.task?.completedAt &&
                            format(
                              new Date(notification.task?.completedAt || 0),
                              'PPp',
                            )}
                        </NotiContent>
                        <NotiDetails
                          read={notification.read}
                          onClick={() =>
                            onClickNotification(notification.task?.id)
                          }
                        >
                          View Details
                        </NotiDetails>
                      </div>
                    </NotiListItem>
                  ))}
                </NotiList>
              </>
            )}
          </NotiListWrapper>
        </ContextMenu>
      </RelativeContainer>
    )
  },
)

export default NotificationsButton
