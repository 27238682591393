export enum TaskKind {
  PeopleUpload = 'people_upload',
  HubSpotSync = 'hubspot_sync',
  NylasEmailSync = 'nylas_email_sync',
  NylasCalendarSync = 'nylas_calendar_sync',
  NylasContactsSync = 'nylas_contacts_sync',
  PDL = 'pdl_enrichment',
  iScraper = 'iscraper_enrichment',
  Humantic = 'humantic_enrichment',
  Brand = 'brand_enrichment',
}

export enum INotificationStatus {
  Pending = 'pending',
  Processing = 'processing',
  Completed = 'completed',
  Cancelled = 'cancelled',
  Error = 'error',
}

export function getTaskKindDisplayName(kind: TaskKind): string {
  switch (kind) {
    case TaskKind.PeopleUpload:
      return 'People Upload'
    case TaskKind.HubSpotSync:
      return 'HubSpot Sync'
    case TaskKind.NylasEmailSync:
      return 'Email Sync'
    case TaskKind.NylasCalendarSync:
      return 'Calendar Sync'
    case TaskKind.NylasContactsSync:
      return 'Contacts Sync'
    case TaskKind.PDL:
      return 'Data Enrichment'
    case TaskKind.iScraper:
      return 'Data Enrichment'
    case TaskKind.Humantic:
      return 'Data Enrichment'
    case TaskKind.Brand:
      return 'Data Enrichment'
    default:
      return 'Unknown Task Kind'
  }
}

export function getNotificationStatusDisplayName(
  status: string | undefined,
): string {
  switch (status) {
    case INotificationStatus.Pending:
      return 'Pending'
    case INotificationStatus.Processing:
      return 'Processing'
    case INotificationStatus.Completed:
      return 'Completed'
    case INotificationStatus.Cancelled:
      return 'Cancelled'
    case INotificationStatus.Error:
      return 'Error'
    default:
      return 'Unknown Status'
  }
}
