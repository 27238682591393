import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { closeGlyph } from 'Assets/Svg'
import { bellGlyph } from 'Assets/Svg/icons'

import { resetButton } from 'Components/Styles'
import { IconButton } from 'Components/UI'

export const IconButtonStyled = styled(IconButton)`
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.02),
    0px 0px 13px -15px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
`

export const BellIcon = styled(bellGlyph)`
  stroke-width: 1px !important;
`

export const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 600px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;
`

export const Hdg = styled.div`
  padding-bottom: 16px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
`

export const CloseIcon = styled(closeGlyph)`
  width: 12px;
  height: 12px;
  fill: ${themeGet('colors.primary')};
  cursor: pointer;
`

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  & > * + * {
    margin-top: 12px;
  }
`

export const Item = styled.li`
  display: flex;
  align-items: baseline;

  & > * + * {
    margin-left: 8px;
  }
`
export const Desc = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  color: #757575;
`

export const SubHdg = styled.div`
  font-size: 12px;
  font-weight: 600;
  margin-top: 12px;
  margin-bottom: 12px;
`
export const Inner = styled.div`
  border-top: 1px solid #dde1e4;
  border-bottom: 1px solid #dde1e4;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 16px;
`
export const Btn = styled.button`
  ${resetButton}
  border-radius: 100px;
  border: 1px solid #dde1e4;
  font-weight: 600;
  font-size: 14px;
  padding: 12px 20px;
  margin-left: auto;
  display: flex;
  cursor: pointer;
`
