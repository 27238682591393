import { useCallback, useState } from 'react'

import { useMutation } from '@apollo/client'
import debounce from 'awesome-debounce-promise'
import { fullNameOrFallback } from 'Features/CommunityUsers/communityUserNameUtil'
import createCommunityUserMutation from 'GraphQL/Mutations/CommunityUser/createCommunityUser.graphql'
import createSkillsMutation from 'GraphQL/Mutations/Skill/createSkills.graphql'
import createTagsMutation from 'GraphQL/Mutations/Tag/createTags.graphql'
import listCommunityUsersQuery from 'GraphQL/Queries/CommunityUser/listCommunityUsers.min.graphql'
import listSkillsQuery from 'GraphQL/Queries/listSkills.graphql'
import listTagsQuery from 'GraphQL/Queries/listTags.graphql'

import map from 'lodash/map'
import trim from 'lodash/trim'

import { ActionKind } from 'Constants/graph'
import { DEFAULT_MIN_SEARCH_SIZE } from 'Constants/ids'

import { useQuery } from 'Services/Apollo'
import _ from 'Services/I18n'
import toast from 'Services/Toast'

const DEBOUNCE_TIME = 500

const NEW_ID = {
  SKILL: 'NEW_SKILL',
  USER: 'NEW_USER',
  TAG: 'NEW_TAG',
}

export interface IOption {
  id: string
  label: string
}

export interface IKindOption extends IOption {
  kind: ActionKind
}

function isSearchShortest(search: string, results: IOption[]) {
  const minLength = results
    .map(item => item.label.length)
    .sort((a, b) => a - b)[0]

  return search.length < minLength
}

function useResource(communityId?: string) {
  const [isLoading, setLoading] = useState(false)
  const [isCreateNewLoading, setCreateNewLoading] = useState(false)

  const [createTags] = useMutation<
    Pick<MainSchema.Mutation, 'createTags'>,
    MainSchema.MutationCreateTagsArgs
  >(createTagsMutation)
  const [createSkills] = useMutation<
    Pick<MainSchema.Mutation, 'createSkills'>,
    MainSchema.MutationCreateSkillsArgs
  >(createSkillsMutation)
  const [createCommunityUser] = useMutation<
    Pick<MainSchema.Mutation, 'createCommunityUser'>,
    MainSchema.MutationCreateCommunityUserArgs
  >(createCommunityUserMutation)

  const { refetch: refetchSkills } = useQuery<
    Pick<MainSchema.Query, 'listSkills'>,
    MainSchema.QueryListSkillsArgs
  >(listSkillsQuery, {
    skip: true,
    variables: {
      communityIds: [communityId!],
      limit: 10,
    },
  })

  const { refetch: refetchTags } = useQuery<
    Pick<MainSchema.Query, 'listTags'>,
    MainSchema.QueryListTagsArgs
  >(listTagsQuery, {
    skip: true,
    variables: communityId
      ? { limit: 10, communityIds: [communityId!] }
      : undefined,
  })

  const { refetch: refetchUsers } = useQuery<
    Pick<MainSchema.Query, 'listCommunityUsers'>,
    MainSchema.QueryListCommunityUsersArgs
  >(listCommunityUsersQuery, {
    skip: true,
    variables: communityId
      ? { communityIds: [communityId], limit: 10 }
      : undefined,
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchSkills = useCallback(
    debounce(async (search: string): Promise<IOption[]> => {
      try {
        const isValid = search?.length >= DEFAULT_MIN_SEARCH_SIZE
        if (!isValid) {
          return []
        }

        setLoading(true)

        const result = await refetchSkills({ search: trim(search) })

        const mappedRes: IOption[] = map(
          result?.data?.listSkills?.rows,
          skill => ({
            label: skill.name!,
            id: skill.id,
          }),
        )

        if (isSearchShortest(search, mappedRes))
          mappedRes.push({ label: search, id: NEW_ID.SKILL })

        return mappedRes.length
          ? [...mappedRes]
          : [{ label: search, id: NEW_ID.SKILL }]
      } finally {
        setLoading(false)
      }
    }, DEBOUNCE_TIME),
    [refetchSkills],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchTags = useCallback(
    debounce(
      async (search: string, kind: MainSchema.TagKind): Promise<IOption[]> => {
        try {
          const isValid = search?.length >= DEFAULT_MIN_SEARCH_SIZE
          if (!isValid) {
            return []
          }

          setLoading(true)

          const result = await refetchTags({
            search: trim(search),
            communityIds: [communityId!],
            kind,
          })

          const options: IOption[] = map(result?.data?.listTags?.rows, tag => ({
            label: `${tag.name}`,
            id: tag.id,
          }))

          if (isSearchShortest(search, options))
            options.push({ label: search, id: NEW_ID.TAG })

          return options.length ? options : [{ label: search, id: NEW_ID.TAG }]
        } finally {
          setLoading(false)
        }
      },
      DEBOUNCE_TIME,
    ),
    [refetchTags],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchUsers = useCallback(
    debounce(async (search: string): Promise<IOption[]> => {
      try {
        if (!communityId) {
          return []
        }

        setLoading(true)
        const result = await refetchUsers({
          search,
          communityIds: [communityId],
        })

        const mappedRes: IOption[] = map(
          result?.data?.listCommunityUsers?.communityUsers,
          communityUser => ({
            label: fullNameOrFallback(communityUser),
            id: communityUser.userId,
          }),
        )

        if (isSearchShortest(search, mappedRes))
          mappedRes.push({ label: search, id: NEW_ID.USER })

        return mappedRes.length
          ? [...mappedRes]
          : [{ label: search, id: NEW_ID.USER }]
      } finally {
        setLoading(false)
      }
    }, DEBOUNCE_TIME),
    [refetchUsers],
  )

  const createNewUser = useCallback(
    async (value: string) => {
      if (!communityId) {
        return null
      }

      setCreateNewLoading(true)

      const names = value.split(' ')

      try {
        const result = await createCommunityUser({
          variables: {
            firstName: trim(names[0]),
            lastName: trim(names[1] || 'last name'),
            communityId,
          },
        })

        toast.success({
          title: 'Create user',
          text: `User successfully created`,
        })

        refetchUsers().then()

        return result.data?.createCommunityUser
      } catch (error) {
        let message = _('error.generic')

        if (error instanceof Error) {
          message = _(`error.${error.message || 'generic'}`)
        }

        toast.error({
          title: 'Create user',
          text: message,
        })

        return null
      } finally {
        setCreateNewLoading(false)
      }
    },
    [communityId, createCommunityUser, refetchUsers],
  )

  const createNewTag = useCallback(
    async (value: string, kind: MainSchema.TagKind) => {
      if (!communityId) {
        return null
      }

      setCreateNewLoading(true)
      try {
        const result = await createTags({
          variables: {
            tags: [
              {
                name: trim(value),
                kind,
                communityId,
              },
            ],
          },
        })

        await refetchTags({ communityIds: [communityId], kind })

        return result?.data?.createTags[0]
      } catch (error) {
        let message = _('error.generic')

        if (error instanceof Error) {
          message = _(`error.${error.message || 'generic'}`, {
            name: value,
          })
        }

        toast.error({
          title: 'Create new tag',
          text: message,
        })
        return null
      } finally {
        setCreateNewLoading(false)
      }
    },
    [communityId, createTags, refetchTags],
  )

  const createNewSkill = useCallback(
    async (value: string) => {
      setCreateNewLoading(true)
      try {
        const result = await createSkills({
          variables: {
            communityId,
            skills: [
              {
                name: trim(value),
              },
            ],
          },
        })

        await refetchSkills()

        return result?.data?.createSkills[0]
      } catch (error) {
        let message = _('error.generic')

        if (error instanceof Error) {
          message = _(`error.${error.message || 'generic'}`, {
            name: trim(value),
          })
        }

        toast.error({
          title: 'Create new skill',
          text: message,
        })

        return null
      } finally {
        setCreateNewLoading(false)
      }
    },
    [communityId, createSkills, refetchSkills],
  )

  return {
    fetchSkills,
    fetchTags,
    fetchUsers,
    createNewUser,
    createNewSkill,
    createNewTag,
    isLoading,
    isCreateNewLoading,
  }
}

export default useResource
