import map from 'lodash/map'

import _ from 'Services/I18n'

export interface IUser {
  firstName?: string
  lastName?: string
}

export function getFullName(value?: IUser | null) {
  return `${value?.firstName || '—'} ${value?.lastName || '—'}`
}

export function getUserLabel(user: {
  firstName?: string
  lastName?: string
  email?: string
}) {
  return (
    `${user.firstName ?? ''} ${user.lastName ?? ''}`.trim() || user.email || '—'
  )
}

export interface IUserValue {
  value: string
  label: string
  user: MainSchema.User
}

export function usersToValues(users: MainSchema.User[]): IUserValue[] {
  return map(users, user => ({
    value: user.id,
    label:
      `${user.profile?.firstName || ''} ${
        user.profile?.lastName || ''
      }`.trim() || '<no name>',
    user,
  }))
}

export function formatExperience(
  workHistory: MainSchema.CommunityUserWorkHistory,
) {
  if (workHistory.jobTitle?.name && workHistory.organization?.name) {
    return _('utils.user.experience.jobTitleAndOrganization', {
      jobTitle: workHistory.jobTitle.name,
      organization: workHistory.organization.name,
    })
  }

  if (workHistory.jobTitle?.name) {
    return _('utils.user.experience.jobTitle', {
      jobTitle: workHistory.jobTitle.name,
    })
  }

  if (workHistory.organization?.name) {
    return _('utils.user.experience.organization', {
      organization: workHistory.organization.name,
    })
  }

  return ''
}

export function formatEducation(
  educationHistory: MainSchema.CommunityUserEducationHistory,
  separator: string = '\n',
) {
  const educationLine1: string[] = []
  const degrees = educationHistory.degrees ?? []
  const majors = educationHistory.majors ?? []
  const minors = educationHistory.minors ?? []

  if (degrees.length > 0) {
    degrees.forEach(degree => {
      educationLine1.push(degree)
    })
  }

  if (majors.length > 0) {
    educationLine1.push(
      _('utils.user.education.majors', {
        majors: majors.join(', '),
      }),
    )
  }

  if (minors.length > 0) {
    educationLine1.push(
      _('utils.user.education.minors', {
        minors: minors.join(', '),
      }),
    )
  }

  let education = educationLine1.join(', ')

  if (educationHistory.organization) {
    if (education) {
      education += separator
    }

    education += educationHistory.organization.name
  }

  return education
}
