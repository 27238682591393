import React from 'react'
import { components, OptionProps } from 'react-select'

import Avatar from 'Components/Blocks/Avatar'
import { Row, Text } from 'Components/UI'

import { AvatarHolder } from './styles'

export interface Option {
  label: string
  communityUser: MainSchema.CommunityUser
}

const SelectOption: React.FC<OptionProps<Option>> = props => {
  const { label, communityUser } = props.data

  return (
    <components.Option {...props}>
      <Row alignItems="center" center fullWidth>
        <AvatarHolder>
          <Avatar size={24} src={communityUser.photoUrl} />
        </AvatarHolder>
        <Text color="inherit">{label}</Text>
      </Row>
    </components.Option>
  )
}

export default SelectOption
